import { Draggable } from "react-beautiful-dnd";

type TableRowProps = {
  data: any;
  index: number;
  isDraggable: boolean;
};

export default function TableRow({ data, index, isDraggable }: TableRowProps): JSX.Element {
  return (
    <Draggable
      draggableId={`${index}`}
      isDragDisabled={!isDraggable}
      index={index}
      key={`item-key-id-${data?.id}-table-row-${index}`}
    >
      {(provided) => (
        <tr
          className="bg-white"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          {data.map((item, indexZ: number) => (
            <td key={`col-${indexZ}`}>{item}</td>
          ))}
          {provided.placeholder}
        </tr>
      )}
    </Draggable>
  );
}
