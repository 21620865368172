export const getFormattedPrice = (price: any = 0, withDecimalRange?: number) => {
  let priceAsNumber;
  if (withDecimalRange) {
    priceAsNumber = parseFloat(price).toFixed(withDecimalRange);
  } else {
    priceAsNumber = parseInt(price);
  }
  const formattedPrice = new Intl.NumberFormat("en-SA", {
    style: "currency",
    currency: "SAR",
  }).format(priceAsNumber);

  return formattedPrice;
};
