import { branchRequestStatus } from "../enums/branch-status.enum";
import { sellerRequestStatus } from "../enums/seller-status.enum";
import { PromotionStatus } from "../enums/promotion-status.enum";
import { Variant } from "../enums/variants.enum";

export const getStatusColor = (status: sellerRequestStatus) => {
  if (status === sellerRequestStatus.APPROVED) {
    return Variant.SUCCESS;
  }
  if (status === sellerRequestStatus.REJECTED) {
    return Variant.DANGER;
  }
  if (status === sellerRequestStatus.PENDING) {
    return Variant.WARNING;
  }
  return Variant.PRIMARY;
};

export const getBranchesStatusColor = (status: branchRequestStatus) => {
  if (status === branchRequestStatus.ACTIVE) {
    return Variant.SUCCESS;
  }
  if (status === branchRequestStatus.INACTIVE || status === branchRequestStatus.DELETED) {
    return Variant.DANGER;
  }
  if (status === branchRequestStatus.PENDING) {
    return Variant.WARNING;
  }
  return Variant.PRIMARY;
};

export const getPromotionStatusColor = (status: PromotionStatus) => {
  if (status === PromotionStatus.ACTIVE) {
    return Variant.SUCCESS;
  }
  if (
    status === PromotionStatus.INACTIVE ||
    status === PromotionStatus.EXPIRED ||
    status === PromotionStatus.DISABLED
  ) {
    return Variant.DANGER;
  }
  if (status === PromotionStatus.SCHEDULED) {
    return Variant.WARNING;
  }
  return Variant.PRIMARY;
};

export const switchArrayToObject = (array = []) => {
  if (!array?.length) {
    return null;
  }

  const newObject: any = {};

  Object.values(array).forEach((item) => {
    newObject[item] = true;
  });

  return newObject;
};
