import React from "react";
import { Route } from "react-router-dom";
import { withAuth } from "../core/helpers/withAuth";
import { withSuspense } from "../core/helpers/withSuspense";
import { DashboardRouteUtil } from "../core/routes/dashboard.route";

const PermissionsModule = React.lazy(() => import(`../pages/dashboard/permissions`));

const CreateUserModule = React.lazy(() => import(`../pages/dashboard/permissions/users/create`));

export const PermissionsRoutes: JSX.Element[] = [
  <Route
    exact
    key="permissions-module"
    path={DashboardRouteUtil.getPermissionsModulePath()}
    component={() => withAuth(withSuspense(PermissionsModule))}
  />,

  <Route
    exact
    key="create-user"
    path={DashboardRouteUtil.getCreateUserModulePath()}
    component={() => withAuth(withSuspense(CreateUserModule))}
  />,
];
