import React from "react";
import { Route } from "react-router-dom";
import { withAuth } from "../core/helpers/withAuth";
import { withSuspense } from "../core/helpers/withSuspense";
import { DashboardRouteUtil } from "../core/routes/dashboard.route";

// Lazy Loaded Modules (Markting Module)
const MarketingModule = React.lazy(() => import(`../pages/dashboard/marketing`));
const CreatePromotionPage = React.lazy(
  () => import(`../pages/dashboard/marketing/promotions/create`)
);
const EditPromotionPage = React.lazy(() => import(`../pages/dashboard/marketing/promotions/edit`));

const CreateCouponPage = React.lazy(() => import(`../pages/dashboard/marketing/coupons/create`));
const EditCouponPage = React.lazy(() => import(`../pages/dashboard/marketing/coupons/edit`));
// It's corresponding array routes
export const MarketingRoutes: JSX.Element[] = [
  <Route
    exact
    key="marketing-module"
    path={DashboardRouteUtil.getMarketingModulePath()}
    component={() => withAuth(withSuspense(MarketingModule))}
  />,
  <Route
    exact
    key="create-promotion"
    path={DashboardRouteUtil.getPromorionsCreatePath()}
    component={() => withAuth(withSuspense(CreatePromotionPage))}
  />,
  <Route
    exact
    key="Edit-promotion"
    path={DashboardRouteUtil.getPromorionsEditPath()}
    component={() => withAuth(withSuspense(EditPromotionPage))}
  />,
  <Route
    exact
    key="create-coupon"
    path={DashboardRouteUtil.getCouponsCreatePath()}
    component={() => withAuth(withSuspense(CreateCouponPage))}
  />,
  <Route
    exact
    key="Edit-coupon"
    path={DashboardRouteUtil.getCouponsEditPath()}
    component={() => withAuth(withSuspense(EditCouponPage))}
  />,
];
