import { Variant } from "../../core/enums/variants.enum";

type SellerStatusProps = {
  text: string;
  color: Variant;
};

export const SellerStatus = ({ text, color }: SellerStatusProps) => {
  return (
    <div className={`bg-${color}-light text-${color} rounded px-2 w-content mx-1`}>{text}</div>
  );
};
