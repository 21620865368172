export enum sellerStatus {
  LIVE = "live",
  CREATED = "created",
}

export enum sellerRequestStatus {
  ALL = "All",
  APPROVED = "Active",
  PENDING = "Pending",
  REJECTED = "In Active",
}

export enum sellerBranchStatus {
  IN_ACTIVE = 0,
  ACTIVE = 10,
  PENDING = 9,
  DELETED = 8,
}
