import { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import BorderTabs from "../../../components/borderTabs";
import { DashboardWrapper } from "../../../containers/wrappers/dashboard";
import useQuery from "../../../core/hooks/useQuery";
import VouchersListPage from "../voucher/list";
import CouponsList from "./coupons/list";
import PromotionsList from "./promotions/list";

export default function MarketingModule() {
  let query = useQuery();
  const Tabs: string[] = ["Coupons", "Promotions", "Vouchers"];
  const [activeTab, setActiveTab] = useState<string | null>(Tabs[0]);
  const { t } = useTranslation();

  useEffect(() => {
    setActiveTab(Tabs.includes(query.get("name") ?? "") ? query.get("name") : Tabs[0]);
  }, []);

  let currentComponent: JSX.Element = (
    <Spinner animation="border" variant="primary" size="sm" className="mx-2" />
  );
  switch (activeTab) {
    case Tabs[0]:
      currentComponent = <CouponsList />;
      break;
    case Tabs[1]:
      currentComponent = <PromotionsList />;
      break;
    case Tabs[2]:
      currentComponent = <VouchersListPage />;
      break;
  }
  return (
    <DashboardWrapper title={t("wrappers.dashboard.marketing")}>
      <>
        <BorderTabs
          Tabs={Tabs}
          activeTab={activeTab!}
          onChangeTab={setActiveTab}
          primary={true}
          disabled={false}
        />
        <Container className="h-100">{currentComponent}</Container>
      </>
    </DashboardWrapper>
  );
}
