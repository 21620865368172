import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { SidePadding, SidePosition } from "../../core/helpers/style.helper";
import xIcon from "../../assets/icons/close.svg";
import searchIcon from "../../assets/icons/search.svg";
import styles from "./style.module.scss";
import { debounce } from "lodash";

/***
 * Global Search Field
 * @param placeholder
 * @param value
 * @param handleOnSearch
 * @return {JSX.Element}
 */
const SearchField = ({ placeholder = "", value = "", handleOnSearch }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debounced = React.useCallback(debounce(handleOnSearch, 500), []);

  React.useEffect(() => {
    if (value) setSearchTerm(value);
  }, [!!value]);

  const handleOnKeyUp = (event, searchText) => {
    if (event.keyCode === 13) {
      handleOnSearch(searchText);
    }
  };

  return (
    <>
      <img src={searchIcon} alt="search" className={styles.search} style={SidePosition("30px")} />
      <Form.Control
        className="my-3"
        placeholder={placeholder}
        onKeyUp={(event) => handleOnKeyUp(event, searchTerm)}
        style={SidePadding("50px")}
        value={searchTerm}
        onChange={(e) => {
          if (e.target.value === "") {
            handleOnSearch("");
          }
          setSearchTerm(e.target.value);
          debounced(e.target.value, 1000);
        }}
        maxLength={50}
      />
      {searchTerm !== "" && (
        <>
          <button
            className={`${styles.search_btn} btn btn-primary btn-sm d-flex`}
            onClick={() => handleOnSearch(searchTerm)}
          >
            <small>Search</small>
          </button>
          <img
            onClick={() => {
              if (value !== "") {
                handleOnSearch("");
              }
              setSearchTerm("");
            }}
            src={xIcon}
            className={`${styles.clear_btn} pointer`}
          />
        </>
      )}
    </>
  );
};

export default SearchField;
