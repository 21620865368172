import React from "react";
import { Route } from "react-router-dom";
import { withAuth } from "../core/helpers/withAuth";
import { withSuspense } from "../core/helpers/withSuspense";
import { DashboardRouteUtil } from "../core/routes/dashboard.route";

// Lazy Loaded Modules (Cities Module)
const CitiesListPage = React.lazy(() => import(`../pages/dashboard/cities/list`));

const CountriesListPage = React.lazy(() => import(`../pages/dashboard/countries/list`));

const CountriesCreateUpdatePage = React.lazy(
  () => import(`../pages/dashboard/countries/create-edit`)
);

const CitiesCreateUpdatePage = React.lazy(() => import(`../pages/dashboard/cities/create-edit`));

export const CitiesCountiesRoutes: JSX.Element[] = [
  <Route
    exact
    key="Cities Management"
    path={DashboardRouteUtil.getCitiesListPath()}
    component={() => withAuth(withSuspense(CitiesListPage))}
  />,
  <Route
    exact
    key="Cities Management"
    path={DashboardRouteUtil.getCountriesListPath()}
    component={() => withAuth(withSuspense(CountriesListPage))}
  />,
  <Route
    exact
    key="Cities Management"
    path={DashboardRouteUtil.getCountriesCreatePath()}
    component={() => withAuth(withSuspense(CountriesCreateUpdatePage))}
  />,
  <Route
    exact
    key="Cities Management"
    path={DashboardRouteUtil.getCitiesCreatePath()}
    component={() => withAuth(withSuspense(CitiesCreateUpdatePage))}
  />,
  <Route
    exact
    key="Cities Management"
    path={DashboardRouteUtil.getCitiesEditPath()}
    component={() => withAuth(withSuspense(CitiesCreateUpdatePage))}
  />,
  <Route
    exact
    key="Cities Management"
    path={DashboardRouteUtil.getCountriesEditPath()}
    component={() => withAuth(withSuspense(CountriesCreateUpdatePage))}
  />,
];
